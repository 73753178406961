/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import './App.css';

// TODO float right guide link

const posts = [
  {
    title: 'Prolific',
    link: 'https://www.prolific.co',
    earning: '8',
    body: `Prolfic is unlike any other survey site. You can easily earn £8 per hour you use this service. Prolific is designed to collect data for research studies usually hosted by Universities across the world. The researches pay you (and prolific) to answer questions that they can use in their research studies. With prolific you don't get disqualified from surveys. The preselect you using data you have given them. This gaurantees there will be no time you are working where you wont recieve compensation.

I have personally earned over £200 with prolific. Prolific allows you to withdraw cash using Paypal. The minimum withdrawal amount is £5 and this can be attained very easily.

The only real disadvantige to prolific can be the lack of surverys. Sometimes you might go a couple of hours without recieving a survey. Furthermore, surveys work on a first come first surved basis, so it is really easy to miss out.`,
  },
  {
    title: 'YouGov',
    link: 'https://www.yougov.co.uk',
    earning: '5',
    body: `YouGov is another survery site where you will be paid honestly for the time you spend completing surveys. You usually recieve at least one survey a day that takes roughly 8 mins a gives you 50 points (equivalent to 50p). You very rarely get disqualified and the surveys are often of political nature. 
YouGov lets you cash out once you have earned 5000 points (equivalent to £50) by bank transfer. I have only managed to complete this once as it can take about a month or two.

I would recommend YouGov for the same reasons as Prolific. Consistency and an reasonable compensation.`,
  },
  {
    title: 'Amazon MTurk',
    link: 'https://www.mturk.com/',
    earning: '6',
    body: `
Amazon MTurk allows you to complete small tasks requested by other uses in exchange for a small payment. Tasks are often small in nature, such as labelling whats on an image or transcribing a small amount of text. Usually, there is a bulk of similar tasks requested by the same user.

Amazon MTurk requires you to withdraw cash to a US bank account, however you can use a third-party such as Hyperwallet (this is the method suggested by them). 

I would recommend MTurk to anybody who prefers completing tasks to mindlessly responding to surverys. It can be rewarding once you can developed and consistent work flow.
`,
  },
  {
    title: 'Matched Betting',
    link: 'https://www.teamprofit.com/ukbeermoney',
    earning: '30',
    body: 'This is perhaps the fastest way of accumilating money online. It involves using the free bet offers advertised by bookmakers for guaranteed profit. Teamprofit gives a detailed guide on match betting and walks you through every offer. This method can easily net you £30+ per hour.',
  },
  {
    title: 'Coinbase',
    link: 'https://www.coinbase.com/join/newton_2tx6',
    earn: '30',
    time: '25',
    body: 'Coinbase is the worlds most popular cryptocurrency exchange. Coinbase gives us the opportunity to make a quick 30 quide by completing some short quizes. Each quiz is based on a different cryptocurrency or crypto based technology and by answering these questions correctly, you will be rewarded with various cryptocurrencies. These coins can be immediately sold and withdrawn',
  },

  {
    title: 'Free Share with Trading212',
    link: 'https://www.trading212.com/invite/FMh054JE',
    body: 'Trading212 offers a free share to all new users on sign up, this could be worth over a £100. The share can be sold imediialty and the money can be withdrawn',
  },
];

function AppPosts() {
  const p = posts.map((post) => (
    <div className="AppPost">
      <div className="AppPostHeader">
        <h2>
          <a href={post.link}>{post.title}</a>
        </h2>
        {Object.prototype.hasOwnProperty.call(post, 'earning') && (
          <div className="AppPostEarning">
            £
            {post.earning}
            + per hour
          </div>
        )}
        {Object.prototype.hasOwnProperty.call(post, 'earn') && (
          <div className="AppPostEarning">
            £
            {post.earn}
            + in
            {' '}
            {post.time}
            {' '}
            minutes
          </div>
        )}
        {Object.prototype.hasOwnProperty.call(post, 'guide') && (
          <div className="AppPostGuide">
            <a href={post.guide}>Guide</a>
          </div>
        )}
      </div>
      <p>{post.body}</p>
    </div>
  ));
  return <div className="AppPosts">{p}</div>;
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          <a href="/">BeerMoney.uk</a>
        </h1>
        <div className="AppSubHeader">
          A guide to easy money online for all brits.
          {' '}
        </div>
      </header>
      <AppPosts />
      <footer>
        <hr />
        <h2>External Resources:</h2>
        <div className="AppLinks">
          <div className="col">
            <h3>Communities</h3>
            <ul>
              <li>
                <a href="https://www.reddit.com/r/beermoneyuk">
                  Beermoney Subreddit
                </a>
              </li>
              <li>
                <a href="https://www.moneysavingexpert.com">
                  Money Saving Expert
                </a>
              </li>
            </ul>
          </div>
          <div className="col">
            <h3>Cashback</h3>
            <ul>
              <li>
                <a href="https://www.topcashback.co.uk/home">Top cashback</a>
              </li>
            </ul>
          </div>
          <div className="col">
            <h3>Matched Betting</h3>
            <ul>
              <li>
                <a href="https://www.teamprofit.com">Team Profit</a>
              </li>
              <li>
                <a href="https://www.teamprofit.com">Profit Accumulator</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
